import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'

const GuaranteeIcon = ({ src, title }) => (
  <Wrapper src={src} alt={title} />
)

GuaranteeIcon.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
}

export default GuaranteeIcon
