import styled from 'styled-components'
import {
  buttonBackground,
  buttonHoverBackground,
  white,
} from 'styles/colors'
import SearchIcon from 'static/img/icons/searchbutton.svg'

const Button = styled.button`
  background-color: ${buttonBackground};
  padding: 0 13px;
  margin-left: -1px;
  transition: all 0.3s;

  &:hover {
    background-color: ${buttonHoverBackground};
    color: ${white};
    transition: all 0.3s;
  }

  &::before {
    content: '';
    width: 22px;
    height: 22px;
    display: block;
    background-image: url(${SearchIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export default Button
