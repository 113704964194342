/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'reactstrap'
import H2 from 'components/Headings/H2'
import Text from 'components/Text'
import Wrapper from './Wrapper'
import HeadingWrapper from './HeadingWrapper'
import TextWrapper from './TextWrapper'
import ContentWrapper from './ContentWrapper'
import CTAWrapper from './CTAWrapper'

const MainSection = ({
  title, bold, secondtitle, secondbold, text, children, graybg, textfull, textbold, ctaText, ctaIcon, ctaUrl,
}) => (
  <Wrapper graybg={graybg}>
    <Container>
      <Row>
        <Col>
          <Fade duration={1000} bottom>
            <ContentWrapper>
              <HeadingWrapper>
                <H2 margin="0 0 36px" center>{title} <span>{bold}</span> {secondtitle} <span>{secondbold}</span></H2>
              </HeadingWrapper>
              <TextWrapper textfull={textfull}>
                <Text bold={textbold}>{text}</Text>
              </TextWrapper>
              { ctaText &&
                <CTAWrapper>
                  <a href={ctaUrl} target="_blank">
                    <img
                      src={ctaIcon}
                      alt={ctaText}
                    />
                    <Text>{ctaText}</Text>
                  </a>
                </CTAWrapper>
              }
            </ContentWrapper>
          </Fade>
        </Col>
      </Row>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

MainSection.propTypes = {
  title: PropTypes.string,
  bold: PropTypes.string,
  secondtitle: PropTypes.string,
  secondbold: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.element,
  graybg: PropTypes.bool,
  textfull: PropTypes.bool,
  textbold: PropTypes.bool,
  ctaText: PropTypes.string,
  ctaIcon: PropTypes.string,
  ctaUrl: PropTypes.string,
}

export default MainSection
