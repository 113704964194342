/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import H2 from 'components/Headings/H2'
import H3 from 'components/Headings/H3'
import ContactItem from 'components/ContactItem'
import FormInput from 'components/FormInput'
import FormTextarea from 'components/FormTextarea'
import Button from 'components/Button'
import enMessages from 'locales/en/contact.json'
import plMessages from 'locales/pl/contact.json'
import Wrapper from './Wrapper'
import FormCol from './FormCol'
import InputWrapper from './InputWrapper'
import Loading from './Loading'
import Error from './Error'
import FieldError from './FieldError'
import Success from './Success'

const messages = {
  en: enMessages,
  pl: plMessages,
}

const initialState = {
  email: '',
  company: '',
  message: '',
  fieldErrors: {
    email: null,
    company: null,
    message: null,
  },
}

class ContactForm extends React.PureComponent {
  state = {
    ...initialState,
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps ({ success }) {
    // clear all fields on success
    if (success === true && this.props.success === false) {
      this.setState({ ...initialState })
    }
  }

  sendMessage = () => {
    const { email, company, message } = this.state
    const { onSubmit } = this.props

    // reset errors
    this.setState({ fieldErrors: { ...initialState.fieldErrors } })

    const isValid = this.formValidation()

    if (isValid) {
      onSubmit({ from: email, company, message })
    }
  }

  formValidation = () => {
    const { email, company, message } = this.state
    const { language } = this.props

    let errors = {}
    if (!email) {
      errors = { ...errors, email: messages[language].form.emptyEmail }
    } else {
      // Based on: "https://stackoverflow.com/questions/4940120/is-there-a-java-implementation-of-the-html5-input-email-validation/4940155#4940155"
      const emailRegex = /[A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+@[A-Za-z0-9-]+(.[A-Za-z0-9-]+)*/

      if (!emailRegex.test(email)) {
        errors = { ...errors, email: messages[language].form.invalidEmail }
      }
    }

    if (!company) {
      errors = { ...errors, company: messages[language].form.emptyName }
    } else if (!(company.length > 5)) {
      errors = { ...errors, company: messages[language].form.shortName }
    } else if (!(company.length < 500)) {
      errors = { ...errors, company: messages[language].form.longName }
    }

    if (!message) {
      errors = { ...errors, message: messages[language].form.emptyMessage }
    } else if (!(message.length > 20)) {
      errors = { ...errors, message: messages[language].form.shortMessage }
    } else if (!(message.length < 10000)) {
      errors = { ...errors, message: messages[language].form.longMessage }
    }

    if (errors.email || errors.company || errors.message) {
      this.setState({ fieldErrors: { ...errors } })

      return false
    }

    return true
  }

  updateFields = (event) => this.setState({ [event.target.name]: event.target.value })

  render () {
    const { email, company, message, fieldErrors } = this.state
    const { success, loading, error, language } = this.props

    return (
      <Wrapper>
        <Container>
          <Row>
            <Col md={{ size: 3, order: 1 }} xs={{ size: 12, order: 2 }}>
              <H2 margin="0 0 36px"><span>{messages[language].companyDataBoldTitle}</span> {messages[language].companyDataTitle}</H2>
              <H3 mobilecenter="true" black>Orion Sp. z o.o.</H3>
              <ContactItem
                mobilecenter="true"
                padding="10px 0"
                street="ul. Gnieźnieńska 68"
                address="62-006, Bogucin"
                phone="61 851 49 00"
                email="biuro@orionht.pl"
                nip="782-22-29-666"
              />
            </Col>
            <FormCol md={{ size: 7, offset: 2, order: 1 }} xs={{ size: 12, offset: 0, order: 2 }}>
              <H2 margin="0 0 36px"><span>{messages[language].formBoldTitle}</span> {messages[language].formTitle}</H2>

              <form>
                <InputWrapper>
                  <FormInput
                    placeholder={messages[language].form.placeholderEmail}

                    value={email}
                    name="email"
                    onChange={this.updateFields}
                  />

                  { fieldErrors.email && (
                    <FieldError>{ fieldErrors.email }</FieldError>
                  )}
                </InputWrapper>

                <InputWrapper>
                  <FormInput
                    placeholder={messages[language].form.placeholderName}

                    value={company}
                    name="company"
                    onChange={this.updateFields}
                  />

                  { fieldErrors.company && (
                    <FieldError>{ fieldErrors.company }</FieldError>
                  )}
                </InputWrapper>

                <InputWrapper>
                  <FormTextarea
                    placeholder={messages[language].form.placeholderMessage}
                    rows="7"

                    value={message}
                    name="message"
                    onChange={this.updateFields}
                  />

                  { fieldErrors.message && (
                    <FieldError>{ fieldErrors.message }</FieldError>
                  )}
                </InputWrapper>

                {/* TODO: should not be inline */}
                <div style={{ margin: '15px 0', textAlign: 'center' }}>
                  { loading &&
                    <Loading>{messages[language].form.sending}...</Loading>
                  }

                  { error &&
                    <Error>{messages[language].form.error}</Error>
                  }

                  { success &&
                    <Success>{messages[language].form.success}</Success>
                  }
                </div>

                <Button mobilecenter="true" arrow="true" onClick={this.sendMessage}>{messages[language].form.send}</Button>
              </form>
            </FormCol>
          </Row>
        </Container>

      </Wrapper>
    )
  }
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  language: PropTypes.string.isRequired,
}

export default ContactForm
