import styled from 'styled-components'
import { mobile } from 'styles/devices'

const SearchWrapper = styled.div`
  position: relative;
  margin-left: 20%;
  margin-right: 20%;
  z-index: 2;

  @media ${mobile} {
    margin-left: 0;
    margin-right: 0;
  }
`

export default SearchWrapper
