import React from 'react' // eslint-disable-line filenames/match-exported
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import TopSection from 'sections/TopSection'
import NotFoundSection from 'sections/NotFoundSection'
import {
  topSectionInfo,
} from 'constants/homepage'

const NotFoundPage = ({ pageContext: { language } }) => (
  <DefaultLayout language={language}>
    <Helmet>
      <title>404 - nie znaleziono</title>
    </Helmet>

    <TopSection
      title={topSectionInfo.title}
      bold={topSectionInfo.bold}
      secondtitle={topSectionInfo.secondtitle}
      brandstext={topSectionInfo.brandstext}
      brandsList={topSectionInfo.brandsList}
    />
    <NotFoundSection />
  </DefaultLayout>
)

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default NotFoundPage
