import Text from 'components/Text'
import styled from 'styled-components'
import { secondTextColor } from 'styles/colors'

const ItemText = styled(Text)`
  position: relative;
  padding-left: 20px;
  line-height: 26px;
  width: 100%;

  &::before {
    position: absolute;
    top: 10px;
    left: 0;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${secondTextColor};
  }
`

export default ItemText
