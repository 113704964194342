import styled from 'styled-components'
import { mobile } from 'styles/devices'

const Wrapper = styled.div`
  ${(props) => (props.padding && `padding: ${props.padding};`)}

  @media ${mobile} {
    ${(props) => (props.mobilecenter && `text-align: center;`)}
  }
`

export default Wrapper
