import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import RegionsMap from './RegionsMap'
import Representatives from './Representatives'

class RegionsMapWithRepresentatives extends React.PureComponent {
  state = {
    selectedRegion: null,
  }

  selectRegion = (selectedRegion) => this.setState({ selectedRegion })

  render () {
    const { selectedRegion } = this.state
    const { regionsWithRepresentatives, language } = this.props

    return (
      <Row>
        <Col md={{ size: 8, order: 1 }} xs={{ size: 12, order: 2 }} className="mobile-hidden">
          <RegionsMap
            selectedRegion={selectedRegion}
            onSelectRegion={this.selectRegion}
            regionsWithRepresentatives={regionsWithRepresentatives}
            language={language}
          />
        </Col>
        <Col md={{ size: 3, offset: 1, order: 1 }} xs={{ size: 10, offset: 1, order: 2 }}>
          <Representatives
            selectedRegion={selectedRegion}
            onSelectRegion={this.selectRegion}
            regionsWithRepresentatives={regionsWithRepresentatives}
            language={language}
          />
        </Col>
      </Row>
    )
  }
}

RegionsMapWithRepresentatives.propTypes = {
  regionsWithRepresentatives: PropTypes.arrayOf(PropTypes.object),
  language: PropTypes.string.isRequired,
}

export default RegionsMapWithRepresentatives
