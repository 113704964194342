import { graphql } from 'gatsby'

// Fragment of entity's fields
export const PRODUCT_FIELDS_FRAGMENT = `
  data {
    id
    attributes {
      catalog
      manufacturer
      description
      tags
      file {
        data {
          id
          attributes {
            url
            size
          }
        }
      }
    } 
  }
`

export const query = graphql`
  fragment ProductFieldsFragment on STRAPI_PRODUCT {
    id
    catalog
    manufacturer
    description
    tags
  }
`
