import styled from 'styled-components'
import { secondTextColor } from 'styles/colors'
import { mobile } from 'styles/devices'

const ItemTitle = styled.h3`
  color: ${secondTextColor};
  font-size: 30px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 1vw auto 3vw;

  @media ${mobile} {
    font-size: 28px;
  }
`

export default ItemTitle
