/* eslint-disable import/max-dependencies */
import React from 'react'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'reactstrap'
import H2 from 'components/Headings/H2'
import ImageSrc from 'static/img/page-not-found.png'
import Wrapper from './Wrapper'
import ContentWrapper from './ContentWrapper'
import Image from './Image'

const NotFoundSection = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          <Fade duration={1000} bottom>
            <ContentWrapper>
              <Image src={ImageSrc} alt="Page not found" />
              <H2 size="29px"><span>Ups... </span>coś poszło nie tak</H2>
            </ContentWrapper>
          </Fade>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

export default NotFoundSection
