import styled from 'styled-components'
import {
  tablet,
  mobile,
} from 'styles/devices'

const ItemCell = styled.div`
  width: ${(props) => (props.itemwidth ? props.itemwidth : '100%')};
  height: 56px;

  @media ${tablet} {
    width: 50%;
    padding: 0 10px;
  }

  @media ${mobile} {
    height: auto;
  }
`

export default ItemCell
