import React from 'react'
import PropTypes from 'prop-types'

import { api } from 'api'
import ContactForm from './ContactForm'

const initialState = {
  success: false,
  loading: false,
  error: null,
}

class ContactFormContainer extends React.PureComponent {
  state = {
    ...initialState,
  }

  submit = async ({ from, company, message }) => {
    this.setState({ ...initialState, loading: true })

    const body = {
      type: 'orion-contact-form',
      from,
      company,
      message,
    }

    try {
      await api.post('/email', body)

      this.setState({ ...initialState, success: true })
    } catch (error) {
      this.setState({ ...initialState, error })
    }
  }

  render () {
    const { success, loading, error } = this.state
    const { language } = this.props

    return (
      <ContactForm
        onSubmit={this.submit}
        success={success}
        loading={loading}
        error={error}
        language={language}
      />
    )
  }
}

ContactFormContainer.propTypes = {
  language: PropTypes.string.isRequired,
}

export default ContactFormContainer
