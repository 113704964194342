import styled from 'styled-components'
import {
  tablet, mobile,
} from 'styles/devices'

const DescriptionWrapper = styled.div`
  width: ${(props) => (props.itemWidth ? props.itemWidth : '100%')};
  padding-right: 5%;

  @media ${tablet} {
    width: ${(props) => (props.tabletwidth ? props.tabletwidth : '100%')};
  }

  @media ${mobile} {
    padding-right: 0;
    padding-left: 27px;
  }
`

export default DescriptionWrapper
