/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes from 'prop-types'
import { graphql as gatsbyGraphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { graphql } from 'api'
import { flattenGraphql } from 'utils/flattenGraphql'
import DefaultLayout from 'layouts/DefaultLayout'
import TopSection from 'sections/TopSection'
import MainSection from 'sections/MainSection'
import { topSectionInfo } from 'constants/homepage'
import RegionsMapWithRepresentatives from 'containers/contact-us/RegionsMapWithRepresentatives'
import ContactsView from 'containers/contact-us/ContactsView'
import ContactForm from 'containers/contact-us/ContactForm'
import { REGION_FIELDS_FRAGMENT } from 'containers/contact-us/RegionFieldsFragment'
import { CONTACT_FIELDS_FRAGMENT } from 'containers/contact-us/ContactFieldsFragment'

import enMessages from 'locales/en/contact.json'
import plMessages from 'locales/pl/contact.json'

const messages = {
  en: enMessages,
  pl: plMessages,
}

/**
 * Query used only for initial data, SEO concerns
 *
 * TODO: query crashes if one field at least is not filed anywhere in collection `representatives`
 */
export const query = gatsbyGraphql`
  query AllRegionsWithRepresentatives {
    allStrapiRegion {
      nodes {
        ...RegionFieldsFragment
      }
    }
    allStrapiContact {
      nodes {
        ... ContactFieldsFragment
      }
    }
  }
`

class ContactPage extends React.PureComponent {
  state = {
    regionsWithRepresentatives: [],
    contacts: [],
  }

  componentDidMount () {
    this.loadRegionsWithRepresentatives()
  }

  fallbackCacheData = (e) => {
    const { data } = this.props
    console.log(e)
    // SEO & SSR cached solution from gatsby build
    if (data && data.allStrapiRegion && data.allStrapiRegion.edges) {
      const nodesArray = data.allStrapiRegion.edges
        .map((edge) => edge.node)

      this.updateRegions({ regions: nodesArray })
    }
  }

  loadRegionsWithRepresentatives = () => {
      graphql(`query AllRegions {
        regions {
          data {
            id
            attributes {
              voivodeship_pl
              voivodeship_en
              representatives {
                data {
                  id
                  attributes {
                    mobile
                    phone
                    email
                    fax
                  }
                }
              }
            }
          }
        }
        contacts {
          data {
            id
            attributes {
              header_en
              header_pl
              help
              phone
              mobile
              email
              fax
            }
          }
        }
      }`)
      .then(this.updateRegions)
      .catch((e) => this.fallbackCacheData(e))
  }

  updateRegions = ({ regions, contacts }) => {

    this.setState({
      regionsWithRepresentatives: flattenGraphql(regions),
      contacts: flattenGraphql(contacts),
    })
  }

  render () {
    const { regionsWithRepresentatives, contacts } = this.state
    const { language } = this.props.pageContext
    return (
      <DefaultLayout language={language}>
        <Helmet>
          <title>{messages[language].headTitle}</title>
        </Helmet>

        <TopSection
          language={language}
          brandsList={topSectionInfo.brandsList}
        />
        <MainSection
          graybg
          title={messages[language].title}
          bold={messages[language].bold}
          text={messages[language].text}
        >
          <RegionsMapWithRepresentatives
            regionsWithRepresentatives={regionsWithRepresentatives}
            language={language}
          />
        </MainSection>
        <ContactsView language={language} contacts={contacts} />
        <ContactForm language={language} />
      </DefaultLayout>
    )
  }
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    allStrapiRegion: PropTypes.object.isRequired,
  }),
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default ContactPage
