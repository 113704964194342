import styled from 'styled-components'
import {
  mapBorder,
  mapBackround,
  mapBorderHover,
} from 'styles/colors'

const Wrapper = styled.svg`
  width: 100%;
  height: 100%;
  overflow: visible;

  .st0 {
    fill: ${mapBackround};
    stroke: ${mapBorder};
  }

  .st_hover {
    fill: ${mapBackround};
    stroke: transparent;
    opacity: 0;
    transition: all 0.3s;
  }

  .clicked,
  .st_hover:hover {
    fill: ${mapBackround};
    stroke: ${mapBorderHover};
    stroke-width: 3;
    filter: url(#dropshadow);
    opacity: 1;
    transform-origin: center;
    transition: all 0.3s;
  }
`

export default Wrapper
