import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import BrandModal from 'components/BrandModal'
import Wrapper from './Wrapper'
import Text from './Text'

const BrandsArea = ({ brandstext, brandsList, language }) => (
  <Wrapper>
    <Text>{brandstext}</Text>
    <Grid
      padding="0"
    >
      {brandsList.map((brand) => (
        <GridItem
          key={brand.id}
          width="14%"
          tabletWidth="14%"
          mobileWidth="14%"
          margin="0"
        >
          {/* TODO: bad approach, should be handled via RootModal */}
          <BrandModal
            language={language}
            catalog={brand.catalog}
            certificate={brand.certificate}
            render={({ onClick }) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img
                src={brand.src}
                alt={brand.name}
                style={brand.certificate ? { cursor: 'pointer' } : {}}
                onClick={brand.certificate ? onClick : null}
                onKeyDown={brand.certificate ? onClick : null}
              />
            )}
          />
        </GridItem>
      ))}
    </Grid>
  </Wrapper>
)

BrandsArea.propTypes = {
  brandstext: PropTypes.string,
  language: PropTypes.string.isRequired,
  brandsList: PropTypes.arrayOf(PropTypes.object),
}

export default BrandsArea
