/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes, { object } from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import Grid from 'components/Grid'
import enMessages from 'locales/en/contact.json'
import plMessages from 'locales/pl/contact.json'
import Wrapper from './Wrapper'
import ContactLink from './ContactLink'
import ContactHeading from './ContactHeading'
import ViewHeading from './ViewHeading'
import GridItemContact from './GridItemContact'

const messages = {
  en: enMessages,
  pl: plMessages,
}

const splitContacts = (contacts) => {
  const contactsList = contacts.filter((contact) => (contact.help === false))
  const contactsHelpList = contacts.filter((contact) => (contact.help === true))

  return { contactsList, contactsHelpList }
}

const ContactsView = ({ language, contacts }) => {
  const { contactsList, contactsHelpList } = splitContacts(contacts)

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={{ size: 12, offset: 0 }} xs={{ size: 10, offset: 1 }}>
            <ViewHeading>{messages[language].headingFirst}</ViewHeading>
            <Grid
              left
            >
              {contactsList.map((contact) => (
                <GridItemContact key={contact.id}>
                  { contact[`header_${language}`] && <ContactHeading>{contact[`header_${language}`]}</ContactHeading>}
                  { contact.phone && <ContactLink href={`tel:${contact.phone}`}>{contact.phone}</ContactLink>}
                  { contact.mobile && <ContactLink href={`tel:${contact.mobile}`} smartphone>{contact.mobile}</ContactLink>}
                  { contact.fax && <ContactLink href={`fax:${contact.fax}`} fax>{contact.fax}</ContactLink>}
                  { contact.email && <ContactLink href={`mailto:${contact.email}`} email>{contact.email}</ContactLink>}
                </GridItemContact>
              ))}
            </Grid>
            {contactsHelpList.length > 0 &&
              <div>
                <ViewHeading>{messages[language].headingSecond}</ViewHeading>
                <Grid
                  left
                >
                  {contactsHelpList.map((contact) => (
                    <GridItemContact key={contact.id}>
                      { contact[`header_${language}`] && <ContactHeading>{contact[`header_${language}`]}</ContactHeading>}
                      { contact.phone && <ContactLink href={`tel:${contact.phone}`}>{contact.phone}</ContactLink>}
                      { contact.mobile && <ContactLink href={`tel:${contact.mobile}`} smartphone>{contact.mobile}</ContactLink>}
                      { contact.fax && <ContactLink href={`fax:${contact.fax}`} fax>{contact.fax}</ContactLink>}
                      { contact.email && <ContactLink href={`mailto:${contact.email}`} email>{contact.email}</ContactLink>}
                    </GridItemContact>
                  ))}
                </Grid>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

ContactsView.propTypes = {
  language: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(object),
}

export default ContactsView
