/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import TopSection from 'sections/TopSection'
import TwoPartsSectionHome from 'sections/TwoPartsSectionHome'
import MainSection from 'sections/MainSection'
import BrandsSectionHome from 'sections/BrandsSectionHome'
import ProductsSection from 'sections/ProductsSection'
import {
  topSectionInfo, brandsList, productsList,
} from 'constants/homepage'

import enMessages from 'locales/en/home.json'
import plMessages from 'locales/pl/home.json'

import enOffer from 'locales/en/offer.json'
import plOffer from 'locales/pl/offer.json'

const messages = {
  en: enMessages,
  pl: plMessages,
}

const lngOffer = {
  en: enOffer,
  pl: plOffer,
}

const HomePage = ({ pageContext: { language } }) => (
  <DefaultLayout language={language}>
    <Helmet>
      <title>{messages[language].title}</title>
    </Helmet>

    <TopSection
      language={language}
      brandsList={topSectionInfo.brandsList}
    />
    <MainSection
      graybg
      title={lngOffer[language].title}
      secondtitle={lngOffer[language].secondtitle}
      bold={lngOffer[language].bold}
      secondbold={lngOffer[language].secondbold}
      text={lngOffer[language].text}
    >
      <ProductsSection
        list={productsList}
        language={language}
      />
    </MainSection>
    <BrandsSectionHome
      title={messages[language].brands.title}
      bold={messages[language].brands.bold}
      text={messages[language].brands.text}
      list={brandsList}
    />
    <TwoPartsSectionHome
      bold={messages[language].about.bold}
      secondtitle={messages[language].about.secondtitle}
      text={messages[language].about.text}
      linktext={messages[language].about.linktext}
      linkto={messages[language].about.linkto}
    />
  </DefaultLayout>
)

HomePage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default HomePage
