import styled from 'styled-components'
import {
  defaultBackground, buttonBackground, white, defaultBorder,
} from 'styles/colors'
import DownloadIconWhite from 'static/img/icons/download-white.svg'

const hoverCss = (props) => (!props.headings && `&:hover {
    background-color: ${defaultBackground};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
    transition: all .3s;

    a {
      color: ${white};
      background-color: ${buttonBackground};
      transition: all .3s;

      &::before {
        background-image: url(${DownloadIconWhite});
      }
    }
  }`)

const Wrapper = styled.div`
  border-bottom: 1px solid ${defaultBorder};
  transition: all 0.3s;
  padding: 10px;
  cursor: pointer;
  ${hoverCss}
`

export default Wrapper
