import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import ContactItem from 'components/ContactItem'
import SelectWrapper from 'components/SelectWrapper'
import enMessages from 'locales/en/contact.json'
import plMessages from 'locales/pl/contact.json'
import Text from 'components/Text'
import Wrapper from './Wrapper'

const messages = {
  en: enMessages,
  pl: plMessages,
}

const Representatives = ({ selectedRegion, onSelectRegion, regionsWithRepresentatives, language }) => {
  const selectOptions = regionsWithRepresentatives.map((region) =>
    ({ value: region.id, label: region[`voivodeship_${language}`] }))

  return (
    <Wrapper>
      <Text bold>{messages[language].regionsTitle}:</Text>

      <SelectWrapper>
        <Select
          className="regions-select"
          classNamePrefix="regions-select"
          value={selectedRegion}
          onChange={onSelectRegion}
          // TODO: handle i18n for voivodeship_pl/en
          options={selectOptions}
        />
      </SelectWrapper>

      {/* Returns representatives for currently selected region */}
      { (selectedRegion && regionsWithRepresentatives) && regionsWithRepresentatives
        .find((region) => region.id === selectedRegion.value).representatives
        .map((representative) => (
          <ContactItem
            key={representative.id}
            padding="10px 0"
            phone={representative.phone}
            smartphone={representative.mobile}
            fax={representative.fax}
            email={representative.email}
          />
        ))
      }
    </Wrapper>
  )
}

Representatives.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedRegion: PropTypes.object,
  onSelectRegion: PropTypes.func,
  regionsWithRepresentatives: PropTypes.arrayOf(PropTypes.object),
  language: PropTypes.string.isRequired,
}

export default Representatives
