import styled from 'styled-components'
import {
  mobile,
  tablet,
} from 'styles/devices'

const HeadingWrapper = styled.div`
  max-width: 230px;
  margin-left: 60%;

  @media ${tablet} {
    max-width: 50%;
    margin-left: 55%;
  }

  @media ${mobile} {
    max-width: 50%;
    margin-left: 50%;
    margin-bottom: 8vw;

    h2 {
      font-size: 20px;
    }
  }
`

export default HeadingWrapper
