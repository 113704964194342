/* eslint-disable import/max-dependencies */
import React from 'react'
import { Parallax } from 'react-parallax'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import H2 from 'components/Headings/H2'
import BgImage from 'static/img/emptyimagebg.png'
import DiamondsImageThin from 'static/img/catalogs/romby-katalogi.png'
import TopPhotoImageThin from 'static/img/catalogs/obrazek.jpg'
import DiamondsImage from 'static/img/diamond-01.png'
import TopPhotoImage from 'static/img/homepage/top-photo.png'
import enCommon from 'locales/en/common.json'
import plCommon from 'locales/pl/common.json'
import Wrapper from './Wrapper'
import HeadingWrapper from './HeadingWrapper'
import ContentWrapper from './ContentWrapper'

const lngTopSectionInfo = {
  en: enCommon.topSectionInfo,
  pl: plCommon.topSectionInfo,
}

const TopSectionThin = ({
  language,
}) => (
  <Wrapper>
    <Container>
      <Parallax
        bgImage={BgImage}
        strength={0}
        style={{
          overflow: 'visible',
        }}
        renderLayer={(percentage) => (
          <div>
            <img
              id="topimagecatalogsmobile"
              src={TopPhotoImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '1%',
                top: '0',
                width: '60%',
              }}
            />
            <img
              id="topdiamondscatalogsmobile"
              src={DiamondsImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '38%',
                top: '-5%',
                width: '47%',
                transform: `translate(-${percentage * 100}%, 0%)`,
              }}
            />
            <img
              id="topimagecatalogs"
              src={TopPhotoImageThin}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '1%',
                top: '0',
                width: '60%',
              }}
            />
            <img
              id="topdiamondscatalogs"
              src={DiamondsImageThin}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '42%',
                top: '-15%',
                width: '50%',
                transform: `translate(-${percentage * 100}%, 0%)`,
              }}
            />
          </div>
        )}
      >
        <Row>
          <Col>
            <Fade right>
              <ContentWrapper>
                <HeadingWrapper>
                  <H2 mobileright>{lngTopSectionInfo[language].title} <span>{lngTopSectionInfo[language].bold}</span>
                    &nbsp;{lngTopSectionInfo[language].secondtitle}
                  </H2>
                </HeadingWrapper>
              </ContentWrapper>
            </Fade>
          </Col>
        </Row>
      </Parallax>
    </Container>
  </Wrapper>
)

TopSectionThin.propTypes = {
  language: PropTypes.string,
}

export default TopSectionThin
