import styled from 'styled-components'
import ArrowIcon from 'static/img/icons/arrow-open-close.svg'
import { tablet } from 'styles/devices'

const arrowCss = (props) => (props.arrow && `  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-image: url(${ArrowIcon});
    background-size: ccontain;
    background-position: center;
    background-repeat: no-repeat;
    width: 6px;
    height: 17px;
    transition: all .3s;
  }`)

const arrowTransform = (props) => (props.isOpen && `  &::before {
    transform: rotate(90deg);
  }`)

const ItemText = styled.div`
  width: ${(props) => (props.itemWidth ? props.itemWidth : '100%')};
  line-height: 56px;
  font-weight: ${(props) => (props.bold ? 'bold' : '400')};
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  ${(props) => (props.arrow && 'padding-left: 10px;')}
  ${(props) => (props.uppercase && 'text-transform: uppercase;')}
  ${arrowCss}
  ${arrowTransform}

  /* stylelint-disable comment-empty-line-before */
  ${(props) => (!props.isOpen ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  ` : `
    margin-top: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  `)}
  /* stylelint-enable comment-empty-line-before */

  @media ${tablet} {
    width: ${(props) => (props.tabletwidth ? props.tabletwidth : '100%')};
    line-height: 36px;
    ${(props) => (props.tabletright && 'text-align: right;')}
  }
`

export default ItemText
