import styled from 'styled-components'

const Input = styled.input`
  font-size: 16px;
  line-height: 19px;
  padding: 13px;
  border-radius: 0;
  border: none;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
`

export default Input
