import styled from 'styled-components'
import { mobile, tablet } from 'styles/devices'

const GridItemContact = styled.div`
  width: 24%;

  @media ${tablet} {
    width: 50%;
  }

  @media ${mobile} {
    width: 50%;
    margin-bottom: 2vw;
  }

  img {
    max-width: 100%;
  }
`

export default GridItemContact
