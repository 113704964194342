import styled from 'styled-components'
import { tablet } from 'styles/devices'

const Wrapper = styled.div`
  display: flex;
  padding: 0 10px;

  @media ${tablet} {
    flex-wrap: wrap;
    padding: 0;
  }
`

export default Wrapper
