import styled from 'styled-components'
import { mobile } from 'styles/devices'

const Label = styled.span`
  display: none;

  @media ${mobile} {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding-left: 11px;
  }
`

export default Label
