/* eslint-disable import/max-dependencies */
import React from 'react'
import { Parallax } from 'react-parallax'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import H2 from 'components/Headings/H2'
import SearchInput from 'components/SearchInput'
import BgImage from 'static/img/emptyimagebg.png'
import DiamondsImage from 'static/img/diamond-01.png'
import DiamondsImageRight from 'static/img/diamond-02.png'
import TopPhotoImage from 'static/img/homepage/top-photo.png'
import enCommon from 'locales/en/common.json'
import plCommon from 'locales/pl/common.json'
import Wrapper from './Wrapper'
import HeadingWrapper from './HeadingWrapper'
import ContentWrapper from './ContentWrapper'
import SearchWrapper from './SearchWrapper'
import BrandsArea from './BrandsArea'

const lngTopSectionInfo = {
  en: enCommon.topSectionInfo,
  pl: plCommon.topSectionInfo,
}

const TopSection = ({
  brandsList, language,
}) => (
  <Wrapper>
    <Container>
      <Parallax
        bgImage={BgImage}
        strength={0}
        style={{
          overflow: 'visible',
        }}
        renderLayer={(percentage) => (
          <div>
            <img
              src={TopPhotoImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '1%',
                top: '0',
                width: '60%',
              }}
            />
            <img
              id="parallaxTopSectionDiamandsLeft"
              src={DiamondsImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '177%',
                top: '-380%',
                width: '47%',
                transform: `translate(-${percentage * 550}%, ${percentage * 500}%)`,
              }}
            />
            <img
              src={DiamondsImageRight}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '50%',
                top: '110%',
                transform: `translate(${percentage * 300}%, -${percentage * 150}%)`,
              }}
            />
          </div>
        )}
      >
        <Row>
          <Col>
            <ContentWrapper>
              <HeadingWrapper>
                <H2 mobileright>
                  {lngTopSectionInfo[language].title}
                  <span> {lngTopSectionInfo[language].bold} </span>
                  {lngTopSectionInfo[language].secondtitle}
                </H2>
              </HeadingWrapper>
              <SearchWrapper>
                <SearchInput language={language} />
              </SearchWrapper>
              <BrandsArea
                language={language}
                brandstext={lngTopSectionInfo[language].brandstext}
                brandsList={brandsList}
              />
            </ContentWrapper>
          </Col>
        </Row>
      </Parallax>
    </Container>
  </Wrapper>
)

TopSection.propTypes = {
  brandsList: PropTypes.arrayOf(PropTypes.object),
  language: PropTypes.string,
}

TopSection.defaultProps = {
  language: 'pl',
}

export default TopSection
