import styled from 'styled-components'
import {
  defaultBorder,
} from 'styles/colors'

const FormInput = styled.input`
  font-size: 14px;
  line-height: 29px;
  padding: 10px;
  border-radius: 0;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${defaultBorder};
  background-color: transparent;
`

export default FormInput
