import styled from 'styled-components'
import Text from 'components/Text'
import PhoneIcon from 'static/img/icons/small-phone.svg'
import SmartphoneIcon from 'static/img/icons/small-smartphone.svg'
import FaxIcon from 'static/img/icons/small-fax.svg'
import EmailIcon from 'static/img/icons/small-mail.svg'
import NipIcon from 'static/img/icons/small-nip.svg'
import AddressIcon from 'static/img/icons/small-address.svg'

const SmartphoneIconCss = (props) => (props.smartphone && `&::before {
  background-image: url(${SmartphoneIcon});
}`)

const FaxIconCss = (props) => (props.fax && `&::before {
  background-image: url(${FaxIcon});
}`)

const EmailIconCss = (props) => (props.email && `&::before {
  background-image: url(${EmailIcon});
}`)

const NipIconCss = (props) => (props.nip && `&::before {
  background-image: url(${NipIcon});
}`)

const AdressIconCss = (props) => (props.street && `&::before {
  background-image: url(${AddressIcon});
}`)

const ContactText = styled(Text)`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding-left: 10px;
  margin: 0;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-image: url(${PhoneIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    transition: all 0.3s;
  }

  span {
    display: block;
    margin-left: 27px;
  }

  ${SmartphoneIconCss}
  ${FaxIconCss}
  ${EmailIconCss}
  ${NipIconCss}
  ${AdressIconCss}
`

export default ContactText
