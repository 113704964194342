import styled from 'styled-components'
import { Col } from 'reactstrap'
import {
  defaultBackground,
} from 'styles/colors'
import {
  mobile,
} from 'styles/devices'

const FormCol = styled(Col)`
  @media ${mobile} {
    padding-top: 5vw;
    padding-bottom: 5vw;
    background-color: ${defaultBackground};
  }
`

export default FormCol
