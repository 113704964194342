import styled from 'styled-components'
import {
  defaultBorder,
} from 'styles/colors'

const FormTextarea = styled.textarea`
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  border-radius: 0;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${defaultBorder};
  background-color: transparent;

  &:focus {
    outline: none;
  }
`

export default FormTextarea
