import styled from 'styled-components'

const ItemHeading = styled.div`
  width: ${(props) => (props.itemWidth ? props.itemWidth : '100%')};
  line-height: 56px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  ${(props) => (props.padding && 'padding-left: 25px;')}
  ${(props) => (props.center && 'text-align: center;')}
`

export default ItemHeading
