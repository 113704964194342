import styled from 'styled-components'
import { tablet } from 'styles/devices'

const ContentWrapper = styled.div`
  display: flex;

  @media ${tablet} {
    flex-wrap: wrap;
    ${(props) => (props.headings && 'display: none;')}
  }
`

export default ContentWrapper
