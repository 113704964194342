import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'

// AWARE: Those collections are synced with CMS regions via `regionId`
const regionPaths = [
  { regionId: 13, paths: 'm52.43,150.7l-1.4,-8.8l-11.9,-2.3l0,-5.2l-7,3.9l3.9,5.7l-6.4,0l0,-4.4l-3.3,-1.3l1,10.6l-3.4,-4.9l-6.3,-5.7l1.3,-8.8l3.9,1.7l2.7,3.3l1.9,-3.3l4.8,1.1l9.1,-2.8l14.6,-8l17.1,-3.4l-8.9,5.3l-1.6,8.8l-6.6,6.1l0,5.7l-3.5,6.7zm-9.5,177.8l-13.1,-9.8l-13.3,-6.6l-12.2,-9.4l-3.3,-10.2l3.3,-16.5l16.1,-4.4l5.9,-15.4l8.6,-18.4l0,-23.4l-8.6,-16.6l0,-33.8l8.6,3.1l8.9,4l3.8,9.9l6.7,-9.9l-6.7,-7.1l6.7,-9.2l4.5,-10l9.1,-12.3l10.4,-3.6l0,-13.8l44.2,-13.4l37.4,-7.6l20.4,-6.7l22.4,-7.7l12.7,-10.7l12.3,-18.9l12,-11.3l16.3,0l12,26.9l0,22.1l-9,7l0,23.3l12.3,17.4l0,22.6l-3.3,24.7l-12.6,7l-4.4,15l-9.1,-2.8l-15.9,0l8.3,12.9l7.7,12.3l9.1,9.9l-9.1,9.2l-7.7,9.8l-8.3,0l-3.9,8.2l-6,12.1l-38.7,0l4.6,-16l-11,0l-4.7,10.4l-9.2,5.6l-12.9,7.6l-6.2,-7.6l-13.6,7.6l-7.2,0l0,12.4l-15,4.1l-10.1,7.8l-3.3,-7.8l-6.3,0l-4.5,-4.1l-5,4.1l-5.9,7.8l0,10.4l-3.9,14.4l-14.5,-5.2l-3.9,5.2l-1.9,-4.6z' },
  { regionId: 10, paths: 'm255.93,39.8l6.9,0l16,-10.7l19.5,-15.5l21.7,-4.3l22.5,0l24.7,-8.3l20.7,0l23.7,3.7l20.9,12.2l13.6,12.2l5.5,10.7l-5.5,3.7l-5.6,-14.4l-24.3,-15.5l-4.6,0l4.6,9.8l4.4,12l6.5,8.1l-6.5,14.4l16.3,12l23,7.4l15.4,3.3l11.1,-3.3l20.8,-7.4l19.2,-12l-2.4,3.8l-12.4,8.2l-11.8,5.6l-11.8,5.1l2.4,4.6l-4,8.7l9,0l3.1,-2.8l-4,11.1l-4.3,17l11.7,8.4l4.1,12.1l16.8,0l-4.8,20.1l-16.1,0l-7.4,19.3l-13.6,16.4l-12,0l-18.9,-5.6l0,-5.1l-38.9,0l-14.3,-10.6l-19.8,-6.4l-16.6,11.3l-13.2,16.4l-18.3,0l-15.9,16.5l-13.2,-8l-22.5,0l-12.8,-18.4l3.4,-1.9l3.3,-24.6l0,-22.5l-12.3,-17.4l0,-23.3l9,-7l0,-22.1l-12,-27' },
  { regionId: 11, paths: 'm498.53,90.1l5.5,-4.9l6.20001,0l10.49997,-10.7l5.70001,0l13.5,-12.7l62.10004,9.2l38.59998,3.5l111.59998,0l28.5,-5.6l30.90002,-4.8l2.5,-1l6.09998,5.7l-8.89996,10.1l-23.10004,11.1l16.10004,13.5l-4.90002,5.6l11.90002,13.8l8.89996,13.49999l0,21.10001l-20.79999,10.8l-11.20001,12.7l-26.09998,15.39999l-20,8.70001l-22.29999,5.89999l-18.10004,0l-23.79999,6.7l-7.39996,15.10001l-24.30005,0l-13.29999,8.8l-19.89996,10.39999l-19.20001,14.70001l-24,0l-14.60004,-10.60001l-17,10.60001l-3.89996,-14.70001l0,-15.89999l-11.79999,0l-17.20004,-8.3l-17.09998,-4.2l0,-21.8l-4.70001,-10.40001l11,-13.29999l7.39999,-19.3l16.10004,0l4.79999,-20.10001l-16.80002,0l-4.10001,-12.1l-11.69998,-8.4l4.29999,-17l4,-11.1' },
  { regionId: 9, paths: 'm814.13,64.1l5.9,-2.4l7.1,-3.8l10.7,7.2l1.9,10.4l10,-3.5l7.1,8.6l15.1,0l9.9,18.2l0,17.2l-3,6.9l5.4,12.9l9.9,42.2l15.6,37.2l10.5,26.2l9.3,9.9l0,17l5,4.2l0,31.2l5.2,9.4l-3.5,13.4l-12.5,10l-9.1,4.2l-17.3,2.6l-5.4,6l-13.1,15.8l-6.9,16l-5.3,13.5l0.1,0l-6.7,0l-11.6,-12.9l-20.3,0l-18.6,-7.8l-5,-18.3l-8.4,-10.4l0,-30.4l-15.4,0l0,-12.3l-22.5,0l-5.9,-13.8l-10.7,-8.4l-4,-7.8l-12.4,-4.8l-11.5,-22.9l6.3,-10.8l-6.3,-17.8l-2,-4.2l8.1,0l22.3,-5.9l20,-8.7l26.1,-15.4l11.2,-12.7l20.8,-10.8l0,-21.1l-8.9,-13.5l-11.9,-13.8l4.9,-5.6l-16.1,-13.5l23.1,-11.1l8.9,-10.1l-6.1,-5.7' },
  { regionId: 5, paths: 'm861.22998,393.60001l-1.69995,6.69998l-6.10004,12.60001l-8.79999,15.60001l-24.40002,-9.5l0,13.10001l-19.19995,-3.60001l-12.30005,9.39999l-33.89996,0l-13.70001,6.5l0,25.10001l13.70001,7.10001l-6.60004,16.19998l-26.79999,10.60001l19.70001,14.69998l0,21.20001l7.09998,8.10004l-7.09998,28.29999l0,14.79999l-14.40002,-4.79999l-18.39996,4.79999l-13,-4.79999l-15.70001,-3.5l-4,-15.10004l-17.20001,4.20001l-14.89996,0l-10.5,-4.20001l-14.40002,-12.39996l0,-7.29999l-12.40002,-8.10004l12.40002,-13.39996l-12.40002,-7.80005l0,-21.89996l12.40002,7.19998l11.20001,-15.69998l-7.29999,-14.5l0,-8.90002l-21.20001,-7.5l-12.10004,0l-5,-12.39999l5,-12.29999l-11.89996,-8.80002l-10.20001,-10.39999l-5.79999,-12.60001l-17.40002,3.10001l-10.29999,0l-14.20001,-9.79999l-10,0l-18,-3.5l0,-15.60001l12.80002,-3.60001l-4,-14.89999l9.19998,-8.89999l-5.19998,-7.20001l5.19998,-9.10001l10,-3l-15.19998,-17.39999l5.19998,-11.79999l19.60004,-7.89999l0,-15l7.69995,-15l1.5,6l17,-10.60001l14.60004,10.60001l24,0l19.20001,-14.70001l19.89996,-10.39999l13.29999,-8.8l24.30005,0l7.39996,-15.10001l23.79999,-6.7l10,0l2,4.2l6.30005,17.8l-6.30005,10.8l11.5,22.90001l12.40002,4.79999l4,7.79999l10.70001,8.40002l5.89996,13.79999l22.5,0l0,12.29999l15.40002,0l0,30.40002l8.40002,10.39999l5,18.29999l18.59998,7.80002l20.29999,0l11.60004,12.89999l1.19995,0' },
  { regionId: 2, paths: 'm309.53,209.9l3.5,2.1l15.9,-16.5l18.3,0l13.2,-16.4l16.6,-11.3l19.8,6.4l14.3,10.6l38.9,0l0,5.1l18.9,5.6l12,0l2.6,-3.1l4.7,10.4l0,21.8l17.1,4.2l17.2,8.3l11.8,0l0,15.9l2.4,8.7l-7.7,15l0,15l-19.6,7.9l-5.2,11.8l15.2,17.4l-10,3l-5.2,9.1l5.2,7.2l-9.2,8.9l4,14.9l-12.8,3.6l0,15.6l1.5,0.3l-7.3,3.2l-24.8,-6.7l-20.3,6.7l-13.6,-19.3l-19.2,-3.2l-5.3,-8.9l-11.9,8.9l-17.9,-8.9l-6.4,-9.6l-9.1,3.2l-5.7,-15.9l-12,7.5l-3.5,-14.9l-15.5,0l-6.4,-7.4l9.9,-5.1l0,-21l-9.9,-9.7l-8.4,0l0,-15.9l8.4,-6.7l-8.4,-17.8l14.8,0l-6.4,-11.9l-8.4,-9.8l0,-9.9l3.9,-8.4' },
  { regionId: 4, paths: 'm39.43,336.2l5.4,-2.2l3.9,-5.2l14.5,5.2l3.8,-14.4l0,-10.4l5.9,-7.8l5,-4.1l4.5,4.1l6.3,0l3.3,7.8l10.1,-7.8l15,-4.1l0,-12.4l7.2,0l13.6,-7.6l6.2,7.6l12.9,-7.6l9.2,-5.6l4.7,-10.4l11,0l-4.6,16l3.2,0l0,12.1l-5.2,15l0,19.3l-14,13.1l4.2,19l-9.1,11.9c8.9,5 13.4,8.8 13.4,11.4s-1.4,7.7 -4.3,15.4l0,31.1l19.3,19.2l8.6,18.3l12.8,-5.3l6.4,17.6l1.6,0.7l-4.4,11.4l-15.3,7.5l-4,-12.4l-17.1,-9l-12,13.9l-5.1,14.4l-9.6,10.8l-13,14.5l-15.5,-14.5l-9.6,4.6l-5.1,14l-14.3,-8.7l-11.9,8.7l-9.3,7.4l-0.1,-0.2l0,-15.7l-9.1,-5.5l-11.2,-8.1l0,-19.7l-7.8,-15.7l-4.9,-13.3l12.7,-10.8l0,-36l6.7,-8.3l-6.7,-10.8l-3.8,-8l-8.9,-13l4.9,-8.4l4.1,-11.4l0,-10.4l-4.1,-6.8l-0.4,-0.4z' },
  { regionId: 1, paths: 'm250.63,692.1l0,9.9l12.2,9.9l5.7,5.9l0,8.7l-14.4,0l-14.4,9.2l-12,3.7l-12.3,-17.4l-12.8,-20l-13.4,-5.3l-5,-4.6l12.7,-14.9l12.2,-9.3l-15.6,-15l-9.3,6.3l-18.8,0l-5.6,-13.1l-11.8,0l-6.6,-10.4l-15,3.9l-16.1,-9.1l-11.4,0l-5.5,-20l0,-15.1l-15.1,-5.3l-14.2,5.3l0,15.1l-9.9,6.2l-6.6,-6.2l6.6,-10.9l13.7,-20.9l4.1,-20.8l0,-12.6l-4.1,-6.8l0.1,0.2l9.3,-7.4l12,-8.7l14.3,8.7l5.1,-13.9l9.6,-4.6l15.4,14.5l13,-14.5l9.6,-10.8l5.1,-14.5l12,-13.8l17.1,9l4,12.3l15.2,-7.5l4.4,-11.4l22.7,9.3l0,13.5l25.9,15.8l16.8,0l15.2,-11.9l14.3,0l19.9,11.9l-11,11l11,15.3l16.4,0l0,29.6l3,1.8l-7.8,5.9l-14.9,-7.2l0,25.5l-12.4,10.9l-7.6,12l-6.6,13.7l0,8.7l-8.7,7l0,11l-12.7,3.3l0,13.4l-14.6,8.8l-5.9,5.3l1.9,3.6l-8.4,-2.2' },
  { regionId: 7, paths: 'm383.73,577.8l12,7.5l19.9,6.2l5,-6.2l14.1,12.3l0,15.5l-14.1,17.5l6.1,7.9l-11.1,4.3l5,14.1l6.1,20.2l-16.8,0l5.7,9.3l-13.8,17.5l0,24.4l-25.8,8.6l-6.3,9.9l0,4.5l-2.5,-3l-6.1,11.9l-18.6,0l-9.6,-11.9l-6.8,-12.6l11.1,-5.1l-4.3,-12.8l-22.1,0l-20.2,-12.1l-22.1,-9l-9.5,-2.4l-1.9,-3.6l5.9,-5.3l14.6,-8.8l0,-13.4l12.7,-3.3l0,-11l8.7,-7l0,-8.7l6.6,-13.7l7.6,-12l12.4,-10.9l0,-25.5l14.9,7.2l7.8,-5.9l6.3,3.6l-3.5,10.1l8.2,5.4l23.4,-11.2l1,-2.5' },
  { regionId: 14, paths: 'm608.03,541.5l-5.8,8.9l3.6,11.7l-16.2,0l0,11.3l-19.7,0l-7.6,11.7l7.6,6.4l4.3,6l-4.3,9l-14.2,-9l0,-6l-7.5,6l0,9l-4.3,10.5l-5.3,8.9l-4.6,3.8l-9.6,-8.3l-11.3,4.5l-5.5,-8.9l0,-10.5l-7.3,0l0,-9l-8.9,3.8l-3.6,5.2l-25.1,-15l-20.2,6l-7.8,1.4l0,-1.3l-14.1,-12.3l-5,6.2l-19.9,-6.2l-12,-7.5l2.9,-7.2l-9.8,-12.8l9.8,-8.4l0,-15.1l18.1,6.7l5.8,-15.3l-5.8,-22.9l5.8,-27.3l20.1,3.8l14,-16.1l-3.9,-11.7l0,-16l18.4,0l0,-17.8l14.9,-6.4l0,-10.8l-4.9,-10.3l16.5,4.4l7.3,-3.2l16.5,3.2l10,0l14.2,9.8l10.3,0l17.4,-3.1l5.8,12.6l10.2,10.4l11.9,8.8l-5,12.3l5,12.4l12.1,0l21.2,7.5l0,8.9l7.3,14.5l-11.2,15.7l-12.4,-7.2l0,21.9l12.4,7.8l-12.4,13.4l1.8,1.2' },
  { regionId: 3, paths: 'm861.23,393.6l5.5,0l2.8,4.1l2.4,6.6l12.3,0l7.7,3.4l2.2,0l7.3,4.9l8.3,0l8.4,8.1l2.1,5.3l-2.1,10.4l-3.5,8.8l0,7l-5,10l0,8l-6,6.6l2.2,9.2l0,18.3l14.3,11.8l9.3,8.3l-5.7,12.9l0,9.5l5.7,6.7l0,6.2l10.3,5.1l7.2,7.6l0,5.1l6.2,6l0,8.1l8.6,6.8l8,3.7l6.5,3.1c1.4,1 1.4,2.4 0,4s-5.3,3.7 -11.6,6.2l-6.7,7.9l11.9,8.6l6.5,19.8l0,11.7l-6.5,16l-11.9,3.9l-18.1,7.6l-19.2,17.7l-4.2,-8.9l-7.1,-12.7l-13,6.8l-13.2,-6.8l-7.1,12.7l-39.3,5.9l-8.1,-5.9l-21,0l0,-12.7l12.7,-8l8.4,-12l-16.4,-5.3l-22.6,-4.8l-10.5,-5.6l4.6,-13.6l-10.2,-9l-19,9l-8.3,-18.7l0,-21.1l-5,-8l0,-14.1l7.2,-28.3l-7.2,-8.1l0,-21.3l-19.7,-14.7l26.8,-10.6l6.6,-16.2l-13.8,-7.1l0,-25.1l13.8,-6.6l33.9,0l12.3,-9.4l19.2,3.6l0,-13.1l24.4,9.5l8.8,-15.5l6.1,-12.7l1.5,-6.6z' },
  { regionId: 16, paths: 'm751.13,631.4l-7.3,17.7l-17.9,21.3l0,14l-18.4,4.8l-11,11.7l-8.6,4.9l-10.4,6.6l-17.4,0l-18.1,4.9l-7.2,10.6l-13.7,5l-19.1,-5l-9.9,-15.5l9.9,-11.5l-13.4,-11.7l0,-11.3l-18,6.5l-11.9,4.8l-14.8,-15.5l11.8,-9.3l-8.3,-15.3l-9.1,0l0,-7.8l9.1,-7.5l-10,-6.9l1.2,-1l5.3,-8.9l4.3,-10.5l0,-9l7.5,-6l0,6l14.2,9l4.3,-9l-4.3,-6l-7.6,-6.4l7.6,-11.7l19.7,0l0,-11.3l16.2,0l-3.6,-11.7l5.8,-8.9l10.6,6.9l0,7.3l14.4,12.4l10.5,4.2l14.9,0l17.2,-4.2l4,15.1l15.7,3.5l13,4.8l18.4,-4.8l14.4,4.8l0,-0.6l5,8l0,21.1l5,11.4' },
  { regionId: 15, paths: 'm507.23001,827l-6,8.09998l-10.80002,0l-4,16.90002l-6.69998,7.79999l-12,0l-7.80002,-17.39996l-8.29999,0l-5.5,-27.40002l-15.20001,-8.90002l-10.29999,-26.79999l-25.60001,-3.5l-17.89999,-14.59998l-9.89999,-11.90002l2.5,3l0,-4.5l6.29999,-9.89996l25.79999,-8.60004l0,-24.39996l13.80002,-17.5l-5.70001,-9.30005l16.80002,0l-6.10001,-20.19995l-5,-14.10004l11.10001,-4.29999l-6.10001,-7.90002l14.10001,-17.5l0,-14.19995l7.79999,-1.40002l20.20001,-6l25.09998,15l3.60001,-5.20001l8.89999,-3.79999l0,9l7.30002,0l0,10.5l5.5,8.90002l11.29999,-4.5l9.60004,8.29999l3.39996,-2.79999l10,6.89996l-9.09998,7.5l0,7.79999l9.09998,0l8.29999,15.30005l-11.79999,9.29999l13.5,14.09998l-1.70001,4.79999l-11.79999,0l-18.09998,8.90002l-6.5,0l-5.20001,8.70001l-7.80002,0l0,11l-5.69998,5.20001l8.89999,6.5l-8.89999,5.59998l-9.20001,5.59998l-7.60001,7.60004l-6.19998,11.09998l0,15.10004l6.19998,-5l0,9.39996l11,0l5.80002,11.70001l5.69998,9.59998l7.80002,4.80005l0,17.59998l-6.89999,0' },
  { regionId: 6, paths: 'm689.03,705.1l0,16.9l-6.1,13.6l6.1,6.7l-6.1,6.6l6.1,4.9l-6.1,6.1l6.1,11.6l8.3,0l-3.1,6.4l10.1,7.7l-15.3,6.2l5.2,8.2l10.1,8.9l3.2,8.5l0,16.7l6.7,8.3l-1.5,7.7l-1.8,0.9l-15.9,-4l-7.5,8.5l-11.7,-4.5l0,7.8l-9.8,9.2l-19.2,0l-11.3,-12.5l-10.4,0l-15.6,3.3l-15.8,0l-7.5,9.2l-7.2,10.4l0,11.4l-16.5,-6.7l-8.7,6.7l-9.4,-6.7l3.7,-9.2l-3.7,-11l3.7,-7.4l-14.4,0l-3.4,-8.5l-9.6,-5.6l-4.2,-15.4l-2.4,0l0,-17.6l-7.8,-4.8l-5.7,-9.6l-5.8,-11.7l-11,0l0,-9.4l-6.2,5l0,-15.1l6.2,-11.1l7.6,-7.6l9.2,-5.6l8.9,-5.6l-8.9,-6.5l5.7,-5.2l0,-11l7.8,0l5.2,-8.7l6.5,0l18.1,-8.9l11.8,0l1.7,-4.8l1.3,1.4l11.9,-4.8l18,-6.5l0,11.3l13.4,11.7l-9.9,11.5l9.9,15.5l19.1,5l13.7,-5l7.2,-10.6l18.1,-4.9l17.4,0l10.4,-6.6l1.1,-0.7' },
  { regionId: 8, paths: 'm712.73,850.1l1.5,-7.7l-6.7,-8.3l0,-16.7l-3.2,-8.5l-10.1,-8.9l-5.2,-8.2l15.3,-6.2l-10.1,-7.7l3.1,-6.4l-8.3,0l-6.1,-11.6l6.1,-6.1l-6.1,-4.9l6.1,-6.6l-6.1,-6.7l6.1,-13.6l0,-16.9l7.5,-4.2l11,-11.7l18.4,-4.8l0,-14l17.9,-21.3l7.3,-17.7l3.2,7.3l19,-9l10.2,9l-4.5,13.6l10.5,5.6l22.6,4.8l16.4,5.3l-8.3,12l-12.7,8l0,12.7l21,0l8.1,5.9l39.4,-5.9l7.1,-12.7l13.2,6.8l13,-6.8l7.1,12.7l4.2,8.9l-2.5,2.3l-24,27.5l-15.3,30.5l-14.7,19.5l-11.6,9.1l-2.8,10.2l-5.2,5.3l-9.7,12l4.9,8.1l4.8,7.3l5.2,17.4l-2.7,15.1l-2.5,9.2l8,0l4.3,6.7l7.3,3.2l2.5,10.3l-2.5,5.9l-14.4,-5.9l-7.4,0l-4.5,-4.7l-12.9,0l-15.5,-8.8l-8.5,0l-25.6,-11.4l-6.8,-15.5l-14.3,-7.4l-7,0l-7.3,-8.5l-20.7,0l-6.5,3.1' },
  { regionId: 12, paths: 'm180.43,277.4l35.5,0l6,-12.1l3.9,-8.2l8.3,0l7.7,-9.8l9.1,-9.2l-9.1,-9.9l-7.7,-12.3l-8.3,-12.9l15.9,0l9.1,2.8l4.4,-15l9.2,-5.1l12.8,18.3l22.5,0l9.7,5.9l-3.9,8.5l0,9.9l8.4,9.8l6.4,11.9l-14.7,0l8.4,17.8l-8.4,6.7l0,15.9l8.4,0l9.9,9.8l0,20.9l-9.9,5.2l6.4,7.3l15.5,0l3.5,14.9l12,-7.5l5.6,15.9l9.2,-3.2l6.4,9.6l17.8,8.8l11.9,-8.8l5.3,8.8l19.1,3.3l13.6,19.3l20.3,-6.7l8.2,2.2l4.9,10.3l0,10.8l-14.9,6.4l0,17.8l-18.3,0l0,16l3.9,11.7l-13.8,16.1l-20.1,-3.8l-5.9,27.4l5.9,22.9l-5.9,15.3l-18,-6.8l0,15.1l-9.8,8.4l9.8,12.8l-4,9.7l-23.4,11.2l-8.1,-5.4l3.4,-10.1l-9.3,-5.4l0,-29.6l-16.4,0l-11,-15.3l11,-11l-19.9,-11.9l-14.2,0l-15.3,11.9l-16.7,0l-25.9,-15.8l0,-13.5l-24.3,-9.9l-6.4,-17.6l-12.8,5.3l-8.6,-18.3l-19.3,-19.2l0,-31.1c2.9,-7.7 4.3,-12.8 4.3,-15.4s-4.5,-6.4 -13.4,-11.4l9.1,-11.9l-4.2,-19l14,-13.1l0,-19.3l5.2,-15l0,-12.1z' },
]

const RegionsMap = ({ selectedRegion, onSelectRegion, regionsWithRepresentatives, language }) => {
  const selectOptions = regionsWithRepresentatives.map((region) =>
    ({ value: region.id, label: region[`voivodeship_${language}`] }))

  return (
    <Wrapper width="979" height="912" viewBox="0 0 979 912">
      <filter id="dropshadow" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feComponentTransfer in="SourceAlpha">
          <feFuncR type="discrete" tableValues="0" />
          <feFuncG type="discrete" tableValues="0.3" />
          <feFuncB type="discrete" tableValues="190" />
        </feComponentTransfer>
        <feGaussianBlur stdDeviation="4" />
        <feOffset dx="1" dy="1" result="shadow" />
        <feComposite in="SourceGraphic" in2="shadow" operator="over" />
      </filter>

      {
        regionPaths.map((regionPath) =>
          <path key={regionPath.regionId} className="st0" d={regionPath.paths} />)
      }

      {
        regionPaths.map((regionPath) => (
          <path
            key={regionPath.regionId}
            onClick={
              () => onSelectRegion(selectOptions.find((option) =>
                option.value === regionPath.regionId.toString()))
            }
            className={`${
              (selectedRegion &&
                (selectedRegion.value === regionPath.regionId.toString()))
                ? 'clicked'
                : ''} st_hover`}
            d={regionPath.paths}
          />
        ))
      }
    </Wrapper>
  )
}

RegionsMap.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectedRegion: PropTypes.object,
  onSelectRegion: PropTypes.func,
  regionsWithRepresentatives: PropTypes.arrayOf(PropTypes.object),
  language: PropTypes.string.isRequired,
}

export default RegionsMap
