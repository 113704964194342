import styled from 'styled-components'
import Text from 'components/Text'
import { defaultBorder } from 'styles/colors'
import { mobile } from 'styles/devices'

const ContactHeading = styled(Text)`
  font-size: 14px;
  line-height: 33px;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding-left: 10px;
  width: 80%;
  border-bottom: 1px solid ${defaultBorder};

  @media ${mobile} {
    width: 100%;
  }
`

export default ContactHeading
