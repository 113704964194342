/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import MainSection from 'sections/MainSection'
import TopSection from 'sections/TopSection'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import {
  topSectionInfo, guaranteeList,
} from 'constants/homepage'
import ytLogo from 'static/img/aboutus/yt_dark.svg'
import TwoPartsSection from 'containers/about-us/TwoPartsSection'
import GuaranteeIcon from 'containers/about-us/GuaranteeIcon'
import ItemTitle from 'containers/about-us/ItemTitle'
import ItemText from 'containers/about-us/ItemText'

import enMessages from 'locales/en/about.json'
import plMessages from 'locales/pl/about.json'

const messages = {
  en: enMessages,
  pl: plMessages,
}

const AboutPage = ({ pageContext: { language } }) => (
  <DefaultLayout language={language}>
    <Helmet>
      <title>{messages[language].title}</title>
    </Helmet>

    <TopSection
      language={language}
      brandsList={topSectionInfo.brandsList}
    />
    <MainSection
      textfull
      graybg
      textbold
      bold={messages[language].bold}
      secondtitle={messages[language].secondtitle}
      text={messages[language].text}
    >
      <TwoPartsSection
        text={messages[language].description}
        ctaText={['zobacz nasz ', <strong>film</strong>, <br />, ' wizerunkowy.']}
        ctaUrl="https://www.youtube.com/watch?v=FpjUWT4-eyM"
        ctaIcon={ytLogo}
      />
    </MainSection>
    <MainSection
      title={messages[language].guarantee.title}
      bold={messages[language].guarantee.bold}
    >
      <Grid>
        {guaranteeList.map((item) => (
          <GridItem
            top
            key={item.id}
            width="23%"
          >
            <GuaranteeIcon src={item.src} alt={item.title} />
            <ItemTitle>{messages[language].guaranteeList[item.id].title}</ItemTitle>
            <ItemText>{messages[language].guaranteeList[item.id].text}</ItemText>
            {messages[language].guaranteeList[item.id].textsecond &&
              <ItemText>{messages[language].guaranteeList[item.id].textsecond}</ItemText>}
            {messages[language].guaranteeList[item.id].textthird &&
              <ItemText>{messages[language].guaranteeList[item.id].textthird}</ItemText>}
            {messages[language].guaranteeList[item.id].textfourth &&
              <ItemText>{messages[language].guaranteeList[item.id].textfourth}</ItemText>}
            {messages[language].guaranteeList[item.id].textfifth &&
              <ItemText>{messages[language].guaranteeList[item.id].textfifth}</ItemText>}
          </GridItem>
        ))}
      </Grid>
    </MainSection>
  </DefaultLayout>
)

AboutPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default AboutPage
