/* eslint-disable import/max-dependencies */
import React from 'react'
import Fade from 'react-reveal/Fade'
import H3 from 'components/Headings/H3'
import ImageSrc from 'static/img/icon-searchzero.png'
import ContentWrapper from './ContentWrapper'
import Image from './Image'

const SearchNotFound = () => (
  <Fade duration={1000} bottom>
    <ContentWrapper>
      <Image src={ImageSrc} alt="Page not found" />
      <H3 uppercase size="13px" black>Ups... Niestety nic nie znaleziono</H3>
    </ContentWrapper>
  </Fade>
)

export default SearchNotFound
