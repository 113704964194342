/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'
import DownloadButton from 'components/DownloadButton'
import { downloadFileOnBrowser } from 'utils/downloadFile'
import { humanFilesize } from 'utils/humanFilesize'
import Wrapper from './Wrapper'
import DescriptionWrapper from './DescriptionWrapper'
import ContentWrapper from './ContentWrapper'
import ItemText from './ItemText'
import ItemHeading from './ItemHeading'
import ContentText from './ContentText'
import CollapseContent from './CollapseContent'
import ItemCell from './ItemCell'
import LinkWrapper from './LinkWrapper'

const noMorePsExt = {
  type: 'output',
  filter: (text) => text.replace(/<\/?p[^>]*>/ig, ''),
}

const converterOptions = {
  backslashEscapesHTMLTags: true,
  emoji: true,
  parseImgDimensions: true,
  simpleLineBreaks: true,
  simplifiedAutoLink: true,
  smoothLivePreview: true,
  splitAdjacentBlockquotes: false,
  strikethrough: true,
  tables: true,
  tasklists: true,
  underline: true,
  extensions: [noMorePsExt],
}

const markdownConverter = new showdown.Converter(converterOptions, {})

export default class CatalogItem extends React.Component {
  constructor () {
    super()

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      downloadInProgress: false,
    }
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render () {
    const {
      name, producer, description, file, tags, headings, fileLabel, size,
    } = this.props

    const downloadFile = file && ((event) => {
      event.stopPropagation()
      this.setState({ downloadInProgress: true })
      downloadFileOnBrowser(file, `${name}.${file.split('.').pop()}`).then(() => {
        this.setState({
          downloadInProgress: false,
        })
      })
    })

    return (
      <Wrapper headings={headings}>
        {!headings &&
        <ContentWrapper onClick={this.toggle}>
          <ItemText isOpen={this.state.isOpen} arrow itemWidth="20%" tabletwidth="50%" bold>{name}</ItemText>
          <ItemText uppercase itemWidth="20%" tabletwidth="50%" bold tabletright>{producer}</ItemText>
          <DescriptionWrapper itemWidth="45%" tabletwidth="100%">
            <ItemText
              isOpen={this.state.isOpen}
              dangerouslySetInnerHTML={{ __html: markdownConverter.makeHtml(description) }}
            />
            <CollapseContent isOpen={this.state.isOpen}>
              <ContentText bold>Tags</ContentText>
              <ContentText>{tags}</ContentText>
              <LinkWrapper>
                {downloadFile && <DownloadButton disabled={!file || this.state.downloadInProgress} onClick={downloadFile}>{this.state.downloadInProgress ? `pobieram...` : `pobierz (${humanFilesize(size)})`}</DownloadButton>}
              </LinkWrapper>
            </CollapseContent>
          </DescriptionWrapper>
          <ItemCell width="15%">
            {downloadFile && <DownloadButton disabled={!file || this.state.downloadInProgress} onClick={downloadFile}>{this.state.downloadInProgress ? `pobieram...` : `pobierz (${humanFilesize(size)})`}</DownloadButton>}
          </ItemCell>
        </ContentWrapper>
        }
        {headings &&
        <ContentWrapper headings={headings}>
          <ItemHeading padding itemWidth="20%">{name}</ItemHeading>
          <ItemHeading itemWidth="20%">{producer}</ItemHeading>
          <DescriptionWrapper itemWidth="45%">
            <ItemHeading>{description}</ItemHeading>
          </DescriptionWrapper>
          <ItemHeading center itemWidth="15%">{fileLabel}</ItemHeading>
        </ContentWrapper>
        }
      </Wrapper>
    )
  }
}

CatalogItem.propTypes = {
  name: PropTypes.string,
  producer: PropTypes.string,
  description: PropTypes.string,
  file: PropTypes.string,
  tags: PropTypes.string,
  fileLabel: PropTypes.string,
  headings: PropTypes.bool,
  size: PropTypes.number,
}
