import styled from 'styled-components'
import {
  buttonBackground,
  buttonHoverBackground,
  buttonHoverShadow,
  white,
} from 'styles/colors'
import SearchIcon from 'static/img/icons/searchbutton.svg'

const SearchButton = styled.button`
  background-color: ${buttonBackground};
  border-color: ${buttonBackground};
  border-radius: 0;
  letter-spacing: 0.5px;
  padding: 0 27px;
  line-height: 39px;
  font-size: 12px;
  color: ${white};
  width: 100%;
  transition: all 0.3s;

  &:hover {
    background-color: ${buttonHoverBackground};
    color: ${white};
    transition: all 0.3s;
    box-shadow: ${buttonHoverShadow};
  }

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-image: url(${SearchIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    transition: all 0.3s;
  }
`

export default SearchButton
