import styled from 'styled-components'
import { tablet } from 'styles/devices'

const LinkWrapper = styled.div`
  display: none;

  @media ${tablet} {
    display: block;
    max-width: 150px;
    margin-left: auto;
  }
`

export default LinkWrapper
