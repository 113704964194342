/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import { Parallax } from 'react-parallax'
import { Helmet } from 'react-helmet'
import { PropTypes } from 'prop-types'
import DefaultLayout from 'layouts/DefaultLayout'
import TopSection from 'sections/TopSection'
import TwoPartsSection from 'sections/TwoPartsSection'
import MainSection from 'sections/MainSection'
import ProductsSection from 'sections/ProductsSection'
import BgImage from 'static/img/emptyimagebg.png'
import ImageOffers from 'static/img/offers/items.png'
import DiamondsImage from 'static/img/offers/romby-tyl.png'
import DiamondImage from 'static/img/offers/romby-przod.png'
import {
  topSectionInfo, productsList,
} from 'constants/homepage'

import enOffer from 'locales/en/offer.json'
import plOffer from 'locales/pl/offer.json'

const lngOffer = {
  en: enOffer,
  pl: plOffer,
}

const OffersPage = ({ pageContext: { language } }) => (
  <DefaultLayout language={language}>
    <Helmet>
      <title>Oferta</title>
    </Helmet>

    <TopSection
      language={language}
      brandsList={topSectionInfo.brandsList}
    />
    <MainSection
      graybg
      title={lngOffer[language].title}
      secondtitle={lngOffer[language].secondtitle}
      bold={lngOffer[language].bold}
      secondbold={lngOffer[language].secondbold}
      text={lngOffer[language].text}
    >
      <ProductsSection
        list={productsList}
        language={language}
      />
    </MainSection>
    <TwoPartsSection
      bold={lngOffer[language].order.bold}
      secondtitle={lngOffer[language].order.secondtitle}
      text={lngOffer[language].order.text}
      linktext={lngOffer[language].order.linktext}
      linkto={lngOffer[language].order.linkto}
    >
      <Parallax
        bgImage={BgImage}
        strength={0}
        style={{
          overflow: 'visible',
        }}
        renderLayer={(percentage) => (
          <div>
            <img
              src={DiamondsImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                right: '-52%',
                top: '5%',
                width: '80%',
                transform: `translate(-${percentage * 100}%, 0%)`,
              }}
            />
            <img
              src={ImageOffers}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                left: '27%',
                // top: '30%',
                width: '90%',
              }}
            />
            <img
              src={DiamondImage}
              alt=""
              style={{
                position: 'absolute',
                display: 'block',
                right: '0',
                top: '12vw',
                width: '20%',
                transform: `translate(-${percentage * 200}%, 0%)`,
              }}
            />
          </div>
        )}
      />
    </TwoPartsSection>
  </DefaultLayout>
)

OffersPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default OffersPage
