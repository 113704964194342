import styled from 'styled-components'
import { Button } from 'reactstrap'
import {
  mainTextColor, white, buttonBackground,
} from 'styles/colors'
import DownloadIcon from 'static/img/icons/download-black.svg'
import DownloadIconWhite from 'static/img/icons/download-white.svg'
import { tablet } from 'styles/devices'

const DownloadButton = styled(Button)`
  color: ${mainTextColor};
  text-transform: none;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 50px;
  height: 50px;
  display: block;
  transition: all 0.3s;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  background: none;
  border: none;
  border-radius: 0;

  @media ${tablet} {
    font-size: 12px;
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
  }

  /* stylelint-disable comment-empty-line-before */
  ${(props) => !props.disabled && `
    &:hover {
      color: ${white};
      background-color: ${buttonBackground};
      transition: all 0.3s;
    }

    &:hover::before {
      background-image: url(${DownloadIconWhite});
    }
  `}
  /* stylelint-enable comment-empty-line-before */

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-image: url(${DownloadIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    transition: all 0.3s;
  }
`

export default DownloadButton
