
// eslint-disable-next-line import/prefer-default-export
export const mapFileToUrl = (file) => {
  if (file) {
    if (file.url) {
      return `${process.env.STRAPI_URL}${file.url}`
    } else if (file.publicURL) {
      return `${process.env.GATSBY_URL}${file.publicURL}`
    }
  }

  return null
}
