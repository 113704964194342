import styled from 'styled-components'
import {
  defaultBorder, defaultBackground,
} from 'styles/colors'

const Input = styled.input`
  font-size: 10px;
  line-height: 19px;
  padding: 10px;
  border-radius: 0;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 0;
  border: 1px solid ${defaultBorder};
  background-color: ${defaultBackground};
`

export default Input
