import styled from 'styled-components'

const CTAWrapper = styled.div`
  p {
    font-size: 24px;
    text-align: right;
    width: 350px;
  }

  img {
    float: left;
    margin: 13px 15px 0 0;
    width: 150px;
  }
`

export default CTAWrapper
