import styled from 'styled-components'
import {
  tablet,
} from 'styles/devices'

const ItemCell = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 56px;

  @media ${tablet} {
    display: none;
  }
`

export default ItemCell
