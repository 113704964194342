import React from 'react'
import PropTypes, { object } from 'prop-types'
import { Parallax } from 'react-parallax'
import MainSection from 'sections/MainSection'
import BrandsSection from 'sections/BrandsSection'
import BgImage from 'static/img/emptyimagebg.png'
import DiamondsImage from 'static/img/diamond-03.png'
import DiamondsImageLeft from 'static/img/diamond-04.png'

const BrandsSectionHome = ({
  title, bold, text, list,
}) => (
  <Parallax
    bgImage={BgImage}
    strength={0}
    style={{
      overflow: 'visible',
    }}
    renderLayer={(percentage) => (
      <div>
        <img
          src={DiamondsImageLeft}
          alt=""
          style={{
            position: 'absolute',
            display: 'block',
            left: '8%',
            top: '10%',
            transform: `translate(-${percentage * 300}%, 0%)`,
          }}
        />
        <img
          src={DiamondsImage}
          alt=""
          style={{
            position: 'absolute',
            display: 'block',
            right: '8%',
            top: '10%',
            transform: `translate(${percentage * 200}%, 0%)`,
          }}
        />
      </div>
    )}
  >
    <MainSection
      title={title}
      bold={bold}
      text={text}
    >
      <BrandsSection
        list={list}
        padding="3vw 0"
      />
    </MainSection>
  </Parallax>
)

BrandsSectionHome.propTypes = {
  title: PropTypes.string,
  bold: PropTypes.string,
  text: PropTypes.string,
  list: PropTypes.arrayOf(object),
}

export default BrandsSectionHome
