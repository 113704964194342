import styled from 'styled-components'

const SelectWrapper = styled.div`
  ${(props) => (props.padding && `padding: ${props.padding};`)}

  .regions-select__control {
    border-radius: 0;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
  }

  .regions-select__single-value {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1.5px;
  }

  .regions-select__menu {
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
  }
`

export default SelectWrapper
