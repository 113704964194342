/* eslint-disable import/max-dependencies */
import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes from 'prop-types'
import { graphql as gatsbyGraphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { graphql } from 'api'
import DefaultLayout from 'layouts/DefaultLayout'
import TopSectionThin from 'sections/TopSectionThin'
import MainSection from 'sections/MainSection'
import CatalogsSection from 'sections/CatalogsSection'
import { PRODUCT_FIELDS_FRAGMENT } from 'containers/catalogs/ProductFieldsFragment'
import { flattenGraphql } from 'utils/flattenGraphql'

import enMessages from 'locales/en/catalog.json'
import plMessages from 'locales/pl/catalog.json'

const messages = {
  en: enMessages,
  pl: plMessages,
}

/**
 * Query used only for initial data, SEO concerns
 */
export const query = gatsbyGraphql`
  query AllProductsSeoPlaceholder {
    allStrapiProduct(limit: 30) {
      edges {
        node {
          ...ProductFieldsFragment
        }
      }
    }
  }
`

class CatalogsPage extends React.PureComponent {
  state = {
    products: [],
    start: 0,
    searchQuery: '',
    isLoadMoreAvailable: true,
  }

  componentDidMount () {
    // eslint-disable-next-line react/prop-types
    const { state } = this.props.location
    if (state && (state.search_manufacturer || state.search_name || state.search_tags)) {
      const { search_name, search_tags, search_manufacturer } = state

      this.searchProduct({ name: search_name, tags: search_tags, manufacturer: search_manufacturer })
    } else {
      this.loadProducts({ reset: true })
    }
  }

  // limit for retrieving with offset, each fetch is `QUERY_LOAD_LIMIT` results more
  QUERY_LOAD_LIMIT = 5

  fallbackCacheData = () => {
    const { data } = this.props

    // SEO & SSR cached solution from gatsby build
    if (data && data.allStrapiProduct && data.allStrapiProduct.edges) {
      const nodesArray = data.allStrapiProduct.edges
        .map((edge) => edge.node)

      this.updateCatalog({ products: nodesArray, reset: true })
    }
  }

  loadProducts = ({ reset }) => {
    const { start, searchQuery } = this.state

    // increment for "load more"
    this.setState({ start: start + this.QUERY_LOAD_LIMIT })

    graphql(`
      query AllProducts {
        products(
          sort: "manufacturer"
          pagination: {start: ${start}, limit: ${this.QUERY_LOAD_LIMIT}}
          ${searchQuery && `filters: { ${searchQuery} }`}) {
          ${PRODUCT_FIELDS_FRAGMENT}
        }
      }
    `)
      .then((data) => this.updateCatalog({ products: data.products, reset }))
      .catch(() => this.fallbackCacheData())
  }

  searchProduct = ({ name, manufacturer, tags }) => {
    let andTags = ''
    let searchQuery = ''

    if (typeof name === 'string' && name !== '') {
      searchQuery += ` catalog: { containsi: "${name}" } `
    }

    if (typeof manufacturer === 'string' && manufacturer !== '') {
      searchQuery += ` manufacturer: { containsi: "${manufacturer}" } `
    }

    if (typeof tags === 'string' && tags !== '') {
      // andTags = tags.replace(/^/, '+').split(' ').join(' +')
      // searchQuery += ` tags: { containsi: "${andTags}" } `

      // and: [ {tags:{ containsi: "nierdzewne"}}, {tags:{ containsi: "Łożyska"}}]
      andTags = tags.split(' ').map(tag => `{tags:{ containsi: "${tag}"}}`)
      searchQuery += ` and: [ ${andTags.join(', ')} ]`
    }

    // save for use in `this.loadProducts`
    this.setState({ searchQuery })

    graphql(`
      query AllProducts {
        products(
          sort: "manufacturer"
          pagination: {start: 0, limit: ${this.QUERY_LOAD_LIMIT}}
          ${searchQuery && `filters: { ${searchQuery} }`}) {
          ${PRODUCT_FIELDS_FRAGMENT}
        }
      }
    `)
      .then((data) => this.updateCatalog({ products: data.products, resetWithStart: true }))
  }

  updateCatalog = ({ products, reset, resetWithStart }) => {
    if (reset || resetWithStart) {
      this.setState((prevState) => ({
        products: [],
        start: resetWithStart ? this.QUERY_LOAD_LIMIT : prevState.start,
        isLoadMoreAvailable: true,
      }))
    }

    // TODO: should be more exact than assumption
    // if less than max there is a very high chance it's the last page
    if (products.length < this.QUERY_LOAD_LIMIT) {
      this.setState({ isLoadMoreAvailable: false })
    }

    // merges products on each request
    this.setState((prevState) => ({ products: prevState.products.concat(flattenGraphql(products)) }))
  }

  render () {
    const { products, isLoadMoreAvailable } = this.state
    const { language } = this.props.pageContext

    // TODO: better approach
    let initialSearch = {}
    // eslint-disable-next-line react/prop-types
    const { state } = this.props.location
    if (state && (state.search_manufacturer || state.search_name || state.search_tags)) {
      const { search_name, search_tags, search_manufacturer } = state

      initialSearch = { name: search_name, tags: search_tags, manufacturer: search_manufacturer }
    }

    return (
      <DefaultLayout language={language}>
        <Helmet>
          <title>{messages[language].headTitle}</title>
        </Helmet>

        <TopSectionThin
          language={language}
        />
        <MainSection
          textfull
          graybg
          textbold
          title={messages[language].title}
          bold={messages[language].bold}
        >
          <CatalogsSection
            list={products}
            onSearch={this.searchProduct}
            onLoadMore={this.loadProducts}
            isLoadMoreAvailable={isLoadMoreAvailable}
            language={language}
            initialSearch={initialSearch}
          />
        </MainSection>
      </DefaultLayout>
    )
  }
}

CatalogsPage.propTypes = {
  data: PropTypes.shape({
    allStrapiProduct: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.object.isRequired,
      })),
    }),
  }),
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default CatalogsPage
