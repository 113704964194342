import React from 'react'
import PropTypes from 'prop-types'
import enMessages from 'locales/en/catalog.json'
import plMessages from 'locales/pl/catalog.json'
import SearchButton from './SearchButton'
import SearchGroup from './SearchGroup'
import Wrapper from './Wrapper'
import ItemCell from './ItemCell'

const messages = {
  en: enMessages,
  pl: plMessages,
}

class Search extends React.PureComponent {
  state = {
    name: '',
    manufacturer: '',
    tags: '',
  }

  componentDidMount () {
    const { initialSearch } = this.props

    // TODO: better approach
    if (initialSearch && (initialSearch.name || initialSearch.tags || initialSearch.manufacturer)) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ name: initialSearch.name, tags: initialSearch.tags, manufacturer: initialSearch.manufacturer })
    }
  }

  search = () => {
    const { name, manufacturer, tags } = this.state
    const { onSearch } = this.props

    onSearch({ name, manufacturer, tags })
  }

  searchOnEnter = (event) => {
    const ENTER = 13

    if (event.keyCode === ENTER) {
      this.search()
    }
  }

  updateFields = (event) => this.setState({ [event.target.name]: event.target.value })

  render () {
    const { name, manufacturer, tags } = this.state
    const { language } = this.props

    return (
      <Wrapper>
        <ItemCell itemwidth="20%">
          <SearchGroup
            value={name}
            name="name"
            onChange={this.updateFields}
            onKeyDown={this.searchOnEnter}
            placeholder={messages[language].placeholderName}
            label={messages[language].name}
          />
        </ItemCell>
        <ItemCell itemwidth="20%">
          <SearchGroup
            value={manufacturer}
            name="manufacturer"
            onChange={this.updateFields}
            onKeyDown={this.searchOnEnter}
            placeholder={messages[language].placeholderProducer}
            label={messages[language].producer}
          />
        </ItemCell>
        <ItemCell itemwidth="45%">
          <SearchGroup
            value={tags}
            name="tags"
            onChange={this.updateFields}
            onKeyDown={this.searchOnEnter}
            placeholder={messages[language].placeholderTags}
            label={messages[language].tags}
          />
        </ItemCell>
        <ItemCell itemwidth="15%">
          <SearchButton onClick={this.search} type="submit">{messages[language].search}</SearchButton>
        </ItemCell>
      </Wrapper>
    )
  }
}

Search.propTypes = {
  onSearch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialSearch: PropTypes.object,
  language: PropTypes.string.isRequired,
}

export default Search
