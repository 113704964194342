import styled from 'styled-components'
import { secondBackground } from 'styles/colors'
import { mobile } from 'styles/devices'

const Wrapper = styled.div`
  position: relative;
  background-color: ${secondBackground};
  padding: 5vw 0;

  @media ${mobile} {
    padding: 10vw 0;
  }
`

export default Wrapper
