// eslint-disable-next-line import/prefer-default-export
export const humanFilesize = (kb) => {
  const thresh = 1024
  let bytes = kb

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let unit = 0
  do {
    bytes /= thresh
    unit += 1
  } while (Math.abs(bytes) >= thresh && unit < units.length - 1)

  return `${bytes.toFixed(1)} ${units[unit]}`
}
