import React from 'react'
import PropTypes from 'prop-types'
import Input from 'components/Input'
import StyledInputGroup from './StyledInputGroup'
import Label from './Label'

const SearchGroup = ({
  value, placeholder, onChange, name, label, ...rest
}) => (
  <StyledInputGroup >
    <Input placeholder={placeholder} name={name} value={value} onChange={onChange} {...rest} />
    <Label>{label}</Label>
  </StyledInputGroup>
)

SearchGroup.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
}

export default SearchGroup
