import styled from 'styled-components'

const FieldError = styled.span`
  color: red;
  display: block;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px;
`

export default FieldError
