import React from 'react'
import { InputGroup } from 'reactstrap'
import PropTypes from 'prop-types'
import enCommon from 'locales/en/common.json'
import plCommon from 'locales/pl/common.json'
import Wrapper from './Wrapper'
import Input from './Input'
import Button from './Button'

const lngSearch = {
  en: enCommon.search,
  pl: plCommon.search,
}

class SearchInput extends React.PureComponent {
  state = {
    search: '',
  }

  updateField = (event) => this.setState({ search: event.target.value })

  submitSearch = () => {
    const { search } = this.state

    if (search.length > 2) {
      window.open(`${process.env.ORION_SHOP}?phrase=${search}`, '_blank')
    }
  }

  searchOnEnter = (event) => {
    const ENTER = 13

    if (event.keyCode === ENTER) {
      this.submitSearch()
    }
  }

  render () {
    const { search } = this.state
    const { language } = this.props

    return (
      <Wrapper>
        <InputGroup>
          <Input
            placeholder={lngSearch[language].placeholder}
            value={search}
            onChange={this.updateField}
            onKeyDown={this.searchOnEnter}
          />

          <Button onClick={this.submitSearch} />
        </InputGroup>
      </Wrapper>
    )
  }
}

SearchInput.propTypes = {
  language: PropTypes.string,
}

export default SearchInput
