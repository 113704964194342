import styled from 'styled-components'
import { mobile } from 'styles/devices'

const Wrapper = styled.img`
  width: 65%;

  @media ${mobile} {
    width: 130px;
  }
`

export default Wrapper
