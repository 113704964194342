import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-parallax'
import { Container } from 'reactstrap'
import TwoPartsSection from 'sections/TwoPartsSection'
import BgImage from 'static/img/emptyimagebg.png'
import SecondImageHome from 'static/img/homepage/group.png'
import DiamondsImage from 'static/img/group-2.png'
import Wrapper from './Wrapper'

const TwoPartsSectionHome = ({
  bold, secondtitle, text, linktext, linkto,
}) => (
  <Wrapper>
    <Container>
      <Parallax
        bgImage={BgImage}
        strength={0}
        style={{
          overflow: 'visible',
        }}
        renderLayer={(percentage) => (
          <div>
            <img
              src={SecondImageHome}
              alt=""
              id="paralaxImageHome"
              style={{
                position: 'absolute',
                display: 'block',
                right: '-30%',
                top: '10%',
                width: '80%',
              }}
            />
            <img
              src={DiamondsImage}
              alt=""
              id="paralaxDiamondsHome"
              style={{
                position: 'absolute',
                display: 'block',
                right: '-52%',
                top: '0',
                width: '40%',
                transform: `translate(-${percentage * 200}%, 0%)`,
              }}
            />
          </div>
        )}
      >
        <TwoPartsSection
          bold={bold}
          secondtitle={secondtitle}
          text={text}
          linktext={linktext}
          linkto={linkto}
        />
      </Parallax>
    </Container>
  </Wrapper>
)

TwoPartsSectionHome.propTypes = {
  bold: PropTypes.string,
  secondtitle: PropTypes.string,
  text: PropTypes.string,
  linktext: PropTypes.string,
  linkto: PropTypes.string,
}

export default TwoPartsSectionHome
