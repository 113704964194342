import styled from 'styled-components'
import { InputGroup as ReactInputGroup } from 'reactstrap'

const InputGroup = styled(ReactInputGroup)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default InputGroup
