import styled from 'styled-components'
import {
  mobile,
} from 'styles/devices'

const Wrapper = styled.div`
  @media ${mobile} {
    padding-top: 5vw;
  }
`

export default Wrapper
