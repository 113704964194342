/* eslint-disable import/max-dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import { Parallax } from 'react-parallax'
import { Row, Col } from 'reactstrap'
import Text from 'components/Text'
import BgImage from 'static/img/emptyimagebg.png'
import ImageAboutUs from 'static/img/aboutus/group-2.png'
import DiamondsImage from 'static/img/aboutus/group.png'
import { certList } from 'constants/homepage'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import BrandModal from 'components/BrandModal'
import Wrapper from './Wrapper'
import CertWrapper from './CertWrapper'
import CTAWrapper from './CTAWrapper'

const TwoPartsSection = ({ text, ctaText, ctaIcon, ctaUrl }) => (
  <Row>
    <Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 2 }}>
      { ctaText &&
        <CTAWrapper>
          <a href={ctaUrl} target="_blank">
            <img
              src={ctaIcon}
              alt={ctaText}
            />
            <Text>{ctaText}</Text>
          </a>
        </CTAWrapper>
      }
      <Text margin="2vw 0 0">{text}</Text>
      <CertWrapper>
        <Grid>
          {certList.map((cert) => (
            <GridItem
              key={cert.id}
              width="25%"
              tabletWidth="25%"
              mobileWidth="25%"
            >
              {/* TODO: bad approach, should be handled via RootModal */}
              <BrandModal
                certificate={cert.certificate}
                render={({ onClick }) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    src={cert.src}
                    alt={cert.name}

                    style={{ cursor: 'pointer' }}
                    onClick={onClick}
                    onKeyDown={onClick}
                  />
                )}
              />
            </GridItem>
          ))}
        </Grid>
      </CertWrapper>
    </Col>
    <Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 1 }}>
      <Wrapper>
        <Parallax
          bgImage={BgImage}
          strength={0}
          style={{
            overflow: 'visible',
          }}
          renderLayer={(percentage) => (
            <div>
              <img
                src={ImageAboutUs}
                alt=""
                style={{
                  position: 'absolute',
                  display: 'block',
                  left: '0',
                  top: '3vw',
                  width: '150%',
                }}
              />
              <img
                src={DiamondsImage}
                alt=""
                style={{
                  position: 'absolute',
                  display: 'block',
                  right: '-60%',
                  top: '4vw',
                  width: '80%',
                  transform: `translate(-${percentage * 100}%, 0%)`,
                }}
              />
            </div>
          )}
        />
      </Wrapper>
    </Col>
  </Row>
)

TwoPartsSection.propTypes = {
  text: PropTypes.string,
  ctaText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  ctaIcon: PropTypes.string,
  ctaUrl: PropTypes.string,
}

export default TwoPartsSection
