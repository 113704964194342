import styled from 'styled-components'
import Text from 'components/Text'
import { defaultBorder } from 'styles/colors'

const ViewHeading = styled(Text)`
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 2vw;
  width: 100%;
  border-bottom: 1px solid ${defaultBorder};
`

export default ViewHeading
