import React from 'react' // eslint-disable-line filenames/match-exported
import PropTypes from 'prop-types'
import ContactLink from 'components/ContactLink'
import ContactText from 'components/ContactText'
import ContactHeading from 'components/ContactHeading'
import Wrapper from './Wrapper'

const ContactItem = ({
  padding, mobilecenter, address, street, office, phone, smartphone, fax, email, nip,
}) => (
  <Wrapper padding={padding} mobilecenter={mobilecenter}>
    {office && <ContactHeading>{office}</ContactHeading>}
    {street && <ContactText street>{street}<span>{address}</span></ContactText>}
    {phone && <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>}
    {smartphone && <ContactLink href={`tel:${smartphone}`} smartphone>{smartphone}</ContactLink>}
    {fax && <ContactLink href={`fax:${fax}`} fax>{fax}</ContactLink>}
    {email && <ContactLink href={`mailto:${email}`} email>{email}</ContactLink>}
    {nip && <ContactText nip>{nip}</ContactText>}
  </Wrapper>
)

ContactItem.propTypes = {
  padding: PropTypes.string,
  mobilecenter: PropTypes.string,
  address: PropTypes.string,
  street: PropTypes.string,
  office: PropTypes.string,
  phone: PropTypes.string,
  smartphone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  nip: PropTypes.string,
}

export default ContactItem
