import styled from 'styled-components'
import InputGroup from 'components/InputGroup'
import { tablet } from 'styles/devices'

const StyledInputGroup = styled(InputGroup)`
  padding-right: 30px;

  @media ${tablet} {
    padding-right: 0;
  }
`

export default StyledInputGroup
