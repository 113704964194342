import { saveAs } from 'file-saver'

const downloadBlobOnBrowser = (file) => {
  const fetchOptions = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
  }

  return new Promise((resolve, reject) => {
    fetch(file, fetchOptions)
      .then((response) => {
        if (response.ok) {
          response.blob().then((blob) => {
            resolve(blob)
          }).catch(reject)
        } else {
          reject()
        }
      })
      .catch(reject)
  })
}

// eslint-disable-next-line import/prefer-default-export
export const downloadFileOnBrowser = (file, filename) =>
  new Promise((resolve, reject) => {
    downloadBlobOnBrowser(file).then((blob) => {
      saveAs(blob, filename, true)
      resolve()
    }).catch(reject)
  })
