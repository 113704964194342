import styled from 'styled-components'
import { mobile } from 'styles/devices'

const Wrapper = styled.div`
  margin: 0;
  position: relative;
  width: 100%;

  @media ${mobile} {
    height: 60vw;
  }
`

export default Wrapper
