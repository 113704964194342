import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import { mapFileToUrl } from 'utils/graphqlMapFileToUrl'
import enMessages from 'locales/en/catalog.json'
import plMessages from 'locales/pl/catalog.json'
import CatalogItem from './CatalogItem'
import ButtonWrapper from './ButtonWrapper'
import Search from './Search'
import SearchNotFound from './SearchNotFound'

const messages = {
  en: enMessages,
  pl: plMessages,
}

class CatalogsSection extends React.PureComponent {
  componentDidMount () {
    this.catalogBottomRef = React.createRef()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps ({ list }) {
    // scroll to bottom of catalogs when more products are loaded
    if (this.props.list.length !== 0 && (list !== this.props.list)) {
      this.scrollToLoadButton()
    }
  }

  catalogBottomRef = null

  scrollToLoadButton = () => {
    window.scrollTo({
      top: this.catalogBottomRef.current.offsetTop + window.innerHeight,
      behavior: 'smooth',
    })
  }

  render () {
    const {
      list, onSearch, onLoadMore, isLoadMoreAvailable, initialSearch, language,
    } = this.props

    return (
      <div>
        <Search
          initialSearch={initialSearch}
          onSearch={onSearch}
          language={language}
        />
        <CatalogItem
          headings
          name={messages[language].name}
          producer={messages[language].producer}
          description={messages[language].description}
          fileLabel={messages[language].fileLabel}
        />
        <div>
          { (list.length === 0) && (
            <SearchNotFound />
          )}

          { (list.length > 0) && list.map((product) => (
            <CatalogItem
              key={product.id}
              name={product.catalog}
              producer={product.manufacturer}
              description={product.description}
              tags={product.tags}
              file={product.file.data && mapFileToUrl(product.file.data.attributes)}
              size={product.file.data && product.file.data.attributes.size}
            />
          ))}

          <div ref={this.catalogBottomRef} />
        </div>

        { isLoadMoreAvailable && (
          <ButtonWrapper>
            <Button size="12px" onClick={onLoadMore}>{messages[language].more}</Button>
          </ButtonWrapper>
        )}
      </div>
    )
  }
}

CatalogsSection.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoadMoreAvailable: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialSearch: PropTypes.object,
  language: PropTypes.string.isRequired,
}

export default CatalogsSection
